/* eslint-disable no-unused-vars */
import Vue from "vue";
import CapacityForm from "@/components/capacityform/capacityform.vue";

import { BSkeleton, BSkeletonWrapper } from "bootstrap-vue";
import { getUserProfile } from "@/services/api/workUsers";

export default {
  name: "capacity",
  props: {
    editMode: {
      type: Boolean,
      default: true,
    },
    capacity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loadingHits: true,
      modalSummaryShow: false,
    };
  },
  components: {
    "capacity-form": CapacityForm,
    "b-skeleton": BSkeleton,
    "b-skeleton-wrapper": BSkeletonWrapper,
  },
  created() {},
  mounted() {},
  watch: {
    $route(to, from) {
      // Call resizePreserveCenter() on all maps
      //Vue.$gmapDefaultResizeBus.$emit("resize");
    },
  },
  computed: {
    //google: gmapApi,
    id: () => {
      return this.$route.params.id;
    },
  },
  methods: {
    //postAssignment,
    getUserProfile,
  },
};
